/* Styling the scrollbar */
::-webkit-scrollbar {
    width: 12px;  /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
    background: lightgrey;  /* Color of the track */
}

::-webkit-scrollbar-thumb {
    background: darkgrey;  /* Color of the scroll thumb */
    border-radius: 6px;  /* Rounded corners on scroll thumb */
}

/* Add some hover effect */
::-webkit-scrollbar-thumb:hover {
    background: grey;  /* Color of the scroll thumb on hover */
}
